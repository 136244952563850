import { Component, input } from '@angular/core';

export type IconButtonTheme = 'default' | 'error' | 'primary' | 'inverse';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'a[app-icon-button], button[app-icon-button]',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss'],
  host: {
    '[class.error]': 'theme() === "error"',
    '[class.extended]': 'extended()',
    '[class.primary]': 'theme() === "primary"',
    '[class.inverse]': 'theme() === "inverse"',
  },
})
export class IconButtonComponent {
  theme = input<IconButtonTheme>('default');
  extended = input<boolean>(false);
}
